import { Status } from './../../enums/status';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { QrcodeDto } from 'src/app/models';
import { ProductSearchService } from 'src/app/services/product-search.service';
import { OS } from '@core/enum';
import { TDSBreakpointEnum, TDSBreakpointService, TDSDestroyService, gridResponsiveMap } from 'tds-ui/core/services';
import { Platform } from '@angular/cdk/platform';
import { takeUntil } from 'rxjs';
import { COUNTRIES_DICT } from 'src/app/data/countries';
import { HiddenAnimation } from '@animations/hidden';
import { CollapseAnimation } from '@animations/collapse';
import { ProductStatus } from 'src/app/enums/product-status';
import { PointStatus } from 'src/app/enums/point-status';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss'],
  providers: [TDSDestroyService],
  animations: [HiddenAnimation, CollapseAnimation],
})
export class ProductSearchComponent implements OnInit {
  public dataSet: QrcodeDto = new QrcodeDto();
  public qrId: number = 0;
  public androidAppUrl = environment.androidAppUrl;
  public iosAppUrl = environment.iosAppUrl;
  public isLoading: boolean = true;
  public ProductStatus = ProductStatus;
  public PointStatus = PointStatus;
  public Status = Status;
  breakpoint!: TDSBreakpointEnum;

  public COUNTRIES_DICT = COUNTRIES_DICT;

  constructor(
    private productSearchService: ProductSearchService,
    private route: ActivatedRoute,
    private breakpointService: TDSBreakpointService,
    private platform: Platform,
    private destroy$: TDSDestroyService,
    private title: Title
  ) {
    this.route.queryParams.subscribe((data) => {
      if (data['qr']) {
        this.qrId = data['qr'];
        this.getEntity();
      }
    });
  }

  ngOnInit(): void {
    this.handleBreakpoint();
  }

  //#region handle api
  private getEntity(): void {
    this.isLoading = true;

    this.productSearchService.getEntity(this.qrId).subscribe({
      next: (res) => {
        this.dataSet = res;
        this.title.setTitle(res.productInfo.name);
      },
      error: (err) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
  //#endregion

  getOS() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isIOS = userAgent.includes('iphone') || userAgent.includes('ipad') || userAgent.includes('ipod');
    const isAndroid = userAgent.includes('android');

    if (isIOS) {
      return OS.ios;
    }
    if (isAndroid) {
      return OS.android;
    }

    return OS.other;
  }

  private handleBreakpoint() {
    if (this.platform.isBrowser) {
      this.breakpointService
        .subscribe(gridResponsiveMap)
        .pipe(takeUntil(this.destroy$))
        .subscribe((bp) => {
          this.breakpoint = bp;
        });
    }
  }
}
