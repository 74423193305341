interface dictType {
  [key: string]: string;
}

const COUNTRIES_DICT: dictType = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AD: 'Andorra',
  AO: 'Angola',
  AR: 'Argentina',
  AM: 'Armenia',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Bỉ',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia và herzegovina',
  BW: 'Botswana',
  BR: 'Brazil',
  BN: 'Brunei',
  BG: 'Bulgaria',
  KH: 'Campuchia',
  CM: 'Cameroon',
  CA: 'Canada',
  CL: 'Chile',
  CN: 'Trung Quốc',
  CO: 'Colombia',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Cộng hoà Séc',
  DK: 'Đan Mạch',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Cộng hoà Dominican',
  EC: 'Ecuador',
  EG: 'Ai Cập',
  SV: 'El Salvador',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FJ: 'Fiji',
  FI: 'Phần Lan',
  FR: 'Pháp',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Đức',
  GH: 'Ghana',
  GR: 'Hy Lạp',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GN: 'Guinea',
  HT: 'Haiti',
  HN: 'Honduras',
  HK: 'Hồng Kông',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'Ấn Độ',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Ireland',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Nhật Bản',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: 'Triều Tiên',
  KR: 'Hàn Quốc',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: 'Lào',
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libyan',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MX: 'Mexico',
  MC: 'Monaco',
  MN: 'Mông Cổ',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Hà Lan',
  NZ: 'New zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NO: 'Na Uy',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine',
  PA: 'Panama',
  PG: 'Papua new guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PL: 'Ba Lan',
  PT: 'Bồ Đào Nha',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Romania',
  RU: 'Nga',
  RW: 'Rwanda',
  WS: 'Samoa',
  SA: 'Ả Rập Saudi',
  SN: 'Senegal',
  CS: 'Serbia và montenegro',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon islands',
  SO: 'Somalia',
  ZA: 'Nam Phi',
  ES: 'Tây Ban Nha',
  LK: 'Sri lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SZ: 'Swaziland',
  SE: 'Thuỵ Điển',
  CH: 'Thuỵ Sĩ',
  SY: 'Syria',
  TW: 'Đài Loan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thái Lan',
  TL: 'Đông Timo',
  TN: 'Tunisia',
  TR: 'Thổ Nhĩ Kỳ',
  TM: 'Turkmenistan',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'UAE',
  GB: 'Anh Quốc',
  US: 'Mỹ',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VE: 'Venezuela',
  VN: 'Việt Nam',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};

export { COUNTRIES_DICT };