<tds-card class="w-full !flex flex-col md:shadow-1-xl relative">
  <tds-card-content class="!flex flex-col gap-4 !px-0">
    <div class="h-full w-full block relative">
      <button
        *ngIf="dataList.length && !loading"
        aria-label="left"
        class="rounded-md absolute left-4 bg-white bg-opacity-40 text-neutral-1-400 hover:bg-opacity-70 hover:text-neutral-1-600 z-[1] absolute-centered-y py-2 transition-all"
        (click)="onScrollTo(selectedId > 0 ? selectedId - 1 : dataList.length - 1)"
      >
        <i class="tdsi-chevron-left-fill leading-none text-6xl"></i>
      </button>

      <button
        *ngIf="dataList.length && !loading"
        aria-label="right"
        class="rounded-md absolute right-4 bg-white bg-opacity-40 text-neutral-1-400 hover:bg-opacity-70 hover:text-neutral-1-600 z-[1] absolute-centered-y py-2 transition-all"
        (click)="onScrollTo(selectedId < dataList.length - 1 ? selectedId + 1 : 0)"
      >
        <i class="tdsi-chevron-right-fill leading-none text-6xl"></i>
      </button>

      <div *ngIf="loading" class="flex flex-col gap-4 px-4 relative">
        <app-skeleton block type="image" class="block aspect-[5/4]" size="full"> </app-skeleton>

        <div class="flex gap-4 no-scrollbar overflow-hidden">
          <app-skeleton *ngFor="let a of skeletonSize" type="image" [width]="100" [height]="100" [loading]="loading">
          </app-skeleton>
        </div>
      </div>

      <div
        @hiddenEnter
        *ngIf="!loading"
        [@sliderX]="selectedId"
        class="block w-full aspect-[5/4] relative overflow-hidden"
      >
        <ng-container *ngFor="let data of dataList; let i = index">
          <div *ngIf="selectedId == i" class="max-md:px-2 md:px-4 w-full h-full rounded-md bg-white aspect-[5/4]">
            <img [src]="dataList[i].url" alt="thumbnail" class="h-full w-full object-cover rounded-md" />
          </div>
        </ng-container>

        <div *ngIf="!dataList.length && !loading">
          <img
            src="assets/images/image-default/image-default.svg"
            alt="thumbnail"
            class="h-full w-full object-cover rounded-md px-4"
          />
        </div>
      </div>
    </div>

    <div *ngIf="dataList && dataList.length && !loading" class="w-full h-[100px] px-4">
      <tds-scroll-into-view class="h-full !flex gap-4 no-scrollbar">
        <img
          *ngFor="let data of dataList; let i = index; let last = last"
          alt="thumbnail"
          tds-scroll-into-view
          [scrollID]="i"
          class="w-[100px] h-full object-cover rounded-md opacity-40 transition-all"
          [src]="data.thumbnails[2]"
          [ngClass]="{ '!opacity-100': selectedId == i }"
          (click)="onScrollTo(i)"
        />
      </tds-scroll-into-view>
    </div>

    <div class="w-full flex justify-center gap-1 px-4 h-2">
      <button
        *ngFor="let data of dataList; let i = index"
        aria-label="select image"
        class="bg-neutral-1-50 h-2 w-2 rounded-full transition-all"
        [ngClass]="{ '!bg-primary-1 !w-4': selectedId == i }"
        (click)="onScrollTo(i)"
      ></button>
    </div>
  </tds-card-content>
</tds-card>
