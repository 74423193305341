import { Component } from '@angular/core';
import { OS } from '@core/enum';
import { environment } from '@environments/environment';
import { TDSMenuDTO } from 'tds-ui/menu';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  visibleDrawer = false;

  menuList: TDSMenuDTO[] = [
    {
      //0
      name: 'Trang chủ',
      icon: 'tdsi-dashboard-fill',
      link: 'https://wipointplatform.com',
    },

    {
      //0
      name: 'Tính năng',
      icon: 'tdsi-dashboard-fill',
      link: 'https://wipointplatform.com/wipoint-tinh-nang.html',
    },

    {
      //0
      name: 'Bài viết',
      icon: 'tdsi-dashboard-fill',
      link: 'https://wipointplatform.com/blog.html',
    },
  ];

  public androidAppUrl = environment.androidAppUrl;
  public iosAppUrl = environment.iosAppUrl;

  onToggleMenu(event: any) {
    this.visibleDrawer = !this.visibleDrawer;
  }

  getOS() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isIOS = userAgent.includes('iphone') || userAgent.includes('ipad') || userAgent.includes('ipod');
    const isAndroid = userAgent.includes('android');

    if (isIOS) {
      return OS.ios;
    }
    if (isAndroid) {
      return OS.android;
    }

    return OS.other;
  }
}
