<header class="w-full flex justify-center bg-white">
  <div class="z-10 py-2 pl-4 pr-8 flex items-center justify-between w-full max-w-[calc(80rem-2rem)]">
    <a href="https://wipointplatform.com">
      <img src="assets/images/logos/logo-light.svg" alt="logo" />
    </a>

    <div class="inline-flex items-center max-lg:hidden">
      <div class="flex items-center text-body-1 font-semibold text-neutral-1-900 [&_a]:transition-colors">
        <a
          *ngFor="let data of menuList"
          [href]="data.link"
          class="px-4 py-3 hover:bg-neutral-3-50 cursor-pointer rounded-md"
        >
          {{ data.name }}
        </a>
      </div>
    </div>

    <div class="flex gap-2 items-center max-md:hidden">
      <a
        tds-button-outline
        type="button"
        size="lg"
        color="primary"
        target="_blank"
        [href]="getOS() == 1 ? iosAppUrl : androidAppUrl"
      >
      <span class="flex gap-2">
        <i class="tdsi-mobile-fill leading-none text-lg"></i>
        Tải ứng dụng
      </span>
      </a>
      <!-- <button tds-button-flat-icon size="lg" color="secondary">
        <i class="tdsi-search-fill leading-none text-lg"></i>
      </button> -->
    </div>

    <button tds-button-flat-icon class="md:hidden" size="lg" color="secondary" (click)="onToggleMenu($event)">
      <i class="tdsi-menu-fill leading-none text-lg"></i>
    </button>
  </div>
</header>

<tds-drawer
  placement="right"
  width="100vw"
  [(visible)]="visibleDrawer"
  [closable]="false"
  [bodyStyle]="{ padding: 0 }"
  [title]="undefined"
  [footer]="undefined"
  (onClose)="onToggleMenu($event)"
>
  <ng-container *tdsDrawerContent>
    <header class="w-full flex justify-between items-center py-2 pl-4 pr-8">
      <a href="https://wipointplatform.com">
        <img src="assets/images/logos/logo-light.svg" alt="logo" />
      </a>

      <button tds-button-flat-icon class="md:hidden" size="lg" color="secondary" (click)="onToggleMenu($event)">
        <i class="tdsi-close-fill leading-none text-xl font-semibold"></i>
      </button>
    </header>

    <content class="flex flex-col p-4">
      <a
        *ngFor="let data of menuList"
        [href]="data.link"
        class="px-4 py-3 hover:bg-neutral-3-50 cursor-pointer rounded-md text-body-1 font-semibold"
      >
        {{ data.name }}
      </a>
    </content>
  </ng-container>
</tds-drawer>
