import { EntityDto } from '@core/dto';
import { ProductStatus } from '../enums/product-status';
import { MediaDto } from './media.dto';
import { PointStatus } from '../enums/point-status';

export class ProductDto extends EntityDto {
  refId: number = 0;
  thumbnail: string = '';
  thumbnails: string[] = [];
  barCode: string = '';
  status: ProductStatus = ProductStatus.active;
  pointStatus: PointStatus = PointStatus.none;
  categoryId: number | null = null;
  categoryName: string = '';
  origin: string = '';
  salePrice: number = 0.0;
  length: number = 0.0;
  width: number = 0.0;
  height: number = 0.0;
  get size() {
    return this.length ? `${this.length}*${this.width}*${this.height}` : '';
  }

  weight: number = 0.0;
  volume: number = 0.0;
  weightUnit: number = 1;
  volumeUnit: number = 1;
  descriptions: string = '';
  medias: MediaDto[] = [];
  point?: number;

  constructor(data?: ProductDto) {
    super(data);
    Object.assign(this, data);

    let thumbnailMedia = new MediaDto();
    thumbnailMedia.url = data?.thumbnail!;
    thumbnailMedia.thumbnails = data?.thumbnails!;
    if (this.medias && this.medias.length) {
      this.medias = this.medias.filter((e) => e.url !== data?.thumbnail);
      this.medias.unshift(thumbnailMedia);
    }
  }
}
