<tds-card class="h-full shadow-1-lg !flex flex-col overflow-hidden">
  <div>
    <img tds-card-image [src]="data.thumbnail" alt="thumbnail" class="h-52 w-full object-cover" />
  </div>
  <tds-card-content class="!h-full !flex flex-col gap-1 justify-between">
    <strong>{{ data.name }}</strong>

    <div class="flex items-center gap-2">
      <span class="flex items-center justify-center w-5 h-5 p-px rounded-full">
        <!-- <tds-avatar [size]="16" shape="circle" [tdsSrc]="company.logoThumbnails![0]" alt="logo company"></tds-avatar> -->
        <img
          tds-image
          disablePreview
          fallback="assets/images/image-default/company-default.svg"
          class="rounded-full border border-neutral-1-200 aspect-square w-4"
          alt="logo company"
          [tdsSrc]="company.logoThumbnails![0]"
        />
      </span>
      <strong class="text-neutral-1-400 dark:text-d-neutral-1-400">{{ company.companyName }}</strong>
    </div>

    <footer class="flex justify-between items-center">
      <strong class="flex items-center gap-1 text-accent-1" [ngClass]="{'mix-blend-luminosity': !data.point}">
        <svg  width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_962_143)">
            <path
              d="M8 16.6385C12.4183 16.6385 16 13.0568 16 8.63855C16 4.22027 12.4183 0.63855 8 0.63855C3.58172 0.63855 0 4.22027 0 8.63855C0 13.0568 3.58172 16.6385 8 16.6385Z"
              fill="url(#paint0_linear_962_143)"
            />
            <path
              d="M12.9039 7.04517C12.8769 6.96199 12.8272 6.88803 12.7603 6.83165C12.6934 6.77527 12.6121 6.73872 12.5256 6.72614L9.68898 6.31395L8.42041 3.74358C8.3817 3.66515 8.32182 3.59911 8.24753 3.55294C8.17325 3.50676 8.08753 3.4823 8.00006 3.4823C7.9126 3.4823 7.82688 3.50678 7.75259 3.55296C7.67831 3.59914 7.61843 3.66518 7.57973 3.74361L6.31116 6.31399L3.47457 6.72617C3.38802 6.73875 3.30671 6.7753 3.23984 6.83168C3.17297 6.88805 3.12321 6.96202 3.09618 7.0452C3.06916 7.12838 3.06594 7.21747 3.0869 7.30238C3.10786 7.3873 3.15216 7.46465 3.21479 7.5257L5.26738 9.52645L4.78282 12.3516C4.76805 12.4378 4.77768 12.5264 4.81064 12.6074C4.8436 12.6884 4.89856 12.7586 4.96932 12.81C5.04007 12.8614 5.1238 12.892 5.21103 12.8983C5.29827 12.9046 5.38552 12.8864 5.46294 12.8457L8.00007 11.5119L10.5372 12.8457C10.6146 12.8864 10.7019 12.9046 10.7891 12.8983C10.8763 12.892 10.9601 12.8614 11.0308 12.81C11.1016 12.7586 11.1566 12.6884 11.1895 12.6074C11.2225 12.5264 11.2321 12.4378 11.2174 12.3516L10.7328 9.52645L12.7854 7.5257C12.848 7.46464 12.8923 7.38728 12.9132 7.30236C12.9342 7.21745 12.931 7.12836 12.9039 7.04517Z"
              fill="url(#paint1_linear_962_143)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_962_143"
              x1="8"
              y1="16.6385"
              x2="8"
              y2="0.63855"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FD5900" />
              <stop offset="1" stop-color="#FFDE00" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_962_143"
              x1="8.00007"
              y1="12.8996"
              x2="8.00007"
              y2="3.4823"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFE59A" />
              <stop offset="1" stop-color="#FFFFD5" />
            </linearGradient>
            <clipPath id="clip0_962_143">
              <rect width="16" height="16" fill="white" transform="translate(0 0.63855)" />
            </clipPath>
          </defs>
        </svg>

        {{ data.point | number : "1.0-0" : "vi_VN" }}
      </strong>
      <strong class="text-primary-1 dark:text-d-primary-1">{{ data.salePrice | number : "1.0-0" : "vi_VN" }} ₫</strong>
    </footer>
  </tds-card-content>
</tds-card>
