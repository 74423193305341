<div
  [ngClass]="{
    block: block !== false,
    'inline-block': block === false,
    'animate-pulse bg-neutral-2-200 dark:bg-d-neutral-3-200 overflow-hidden': loading,
    'h-4': loading && size == 'xs',
    'h-6': loading && size == 'sm',
    'h-8': loading && size == 'md',
    'h-10': loading && size == 'lg',
    'h-12': loading && size == 'xl',
    'h-full w-full': loading && size == 'full',
    'rounded-md': loading && (type === 'input' || type == 'image'),
    'rounded-full': loading && type === 'avatar',
    'aspect-square': loading && type === 'avatar',
    'aspect-[5/4]': loading && type === 'image'
  }"
  [style.width]="loading ? (block !== false || type == 'avatar' ? '100%' : width + 'px') : undefined"
  [style.height]="height + 'px'"
>
  <span *ngIf="loading" class="skeleton-loading w-full h-full block"></span>
  <ng-content @hiddenEnter *ngIf="!loading"></ng-content>
</div>
