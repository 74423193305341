import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { HiddenAnimation } from './animations/hidden';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
  animations: [HiddenAnimation],
})
export class SkeletonComponent {
  @Input() loading: boolean = true;
  @Input() type: 'input' | 'avatar' | 'image' = 'input';
  @Input() block: boolean | string = false;
  @Input() width: number | string = 200;
  @Input() height?: number;
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full' | number = 'md';
  @Input() rows: number = 3;
}
