export class VerifyDto {
  avatarUrl: string = '';
  fullName: string = '';
  thumbnails: string[] = [];
  verifyDate: Date = new Date();

  constructor(data?: VerifyDto) {
    Object.assign(this, data);
    this.verifyDate = data?.verifyDate ? new Date(data?.verifyDate) : new Date();
  }
}
