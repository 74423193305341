import { Component, Input } from '@angular/core';
import { CompanyDto, ProductDto } from 'src/app/models';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent {
  @Input() data!: ProductDto;
  @Input() company!: CompanyDto;
}
