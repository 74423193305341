<tds-card class="w-full h-fit shadow-1-lg">
  <tds-card-content class="!flex gap-2">
    <!-- <tds-avatar [size]="44" shape="circle" [tdsSrc]="data.logoUrl" alt="logo company"></tds-avatar> -->
    <app-skeleton class="w-12 h-12 flex-none" [loading]="loading" type="avatar" [width]="48" [height]="48">
      <img
        tds-image
        disablePreview
        fallback="assets/images/image-default/company-default.svg"
        class="rounded-full border-2 border-primary-1 aspect-square w-12"
        alt="logo company"
        [tdsSrc]="data.logoUrl ? data.logoUrl : 'assets/images/image-default/company-default.svg'"
      />
    </app-skeleton>

    <div class="flex flex-col w-full gap-2">
      <div class="flex flex-col h-full justify-between w-full">
        <app-skeleton [loading]="loading" size="xs" [width]="200">
          <p class="text-neutral-1-400 dark:text-d-neutral-1-400">Doanh nghiệp sở hữu</p>
        </app-skeleton>

        <app-skeleton [loading]="loading" size="sm" [width]="300">
          <h6>{{ data.companyName }}</h6>
        </app-skeleton>
      </div>
    </div>
  </tds-card-content>
</tds-card>

<content class="flex flex-col gap-2">
  <div class="flex gap-1">
    <i class="tdsi-price-tag-fill text-lg leading-none text-primary-1 dark:text-d-primary-1"></i>
    <app-skeleton [loading]="loading" size="xs" [width]="150">
      <strong>
        Mã số thuế:
        <p class="font-normal inline" [ngClass]="{ 'text-neutral-1-400 dark:text-d-neutral-1-400': !data.taxCode }">
          {{ data.taxCode || "Chưa cập nhật" }}
        </p>
      </strong>
    </app-skeleton>
  </div>

  <div class="flex gap-1">
    <i class="tdsi-mobile-fill text-lg leading-none text-primary-1 dark:text-d-primary-1"></i>

    <app-skeleton [loading]="loading" size="xs" [width]="150">
      <strong>
        Số điện thoại:
        <a *ngIf="data.phoneNumber" class="font-normal inline" [href]="'tel:' + data.phoneNumber">
          {{ data.phoneNumber }}
        </a>
        <p *ngIf="!data.phoneNumber" class="font-normal inline text-neutral-1-400 dark:text-d-neutral-1-400">
          Chưa cập nhật
        </p>
      </strong>
    </app-skeleton>
  </div>

  <div class="flex gap-1">
    <i class="tdsi-print-fill text-lg leading-none text-primary-1 dark:text-d-primary-1"></i>
    <app-skeleton [loading]="loading" size="xs" [width]="150">
      <strong>
        Fax:
        <p class="font-normal inline" [ngClass]="{ 'text-neutral-1-400 dark:text-d-neutral-1-400': !data.fax }">
          {{ data.fax || "Chưa cập nhật" }}
        </p>
      </strong>
    </app-skeleton>
  </div>

  <div class="flex gap-1">
    <i class="tdsi-email-fill text-lg leading-none text-primary-1 dark:text-d-primary-1"></i>
    <app-skeleton [loading]="loading" size="xs" [width]="150">
      <strong>
        Email:
        <a *ngIf="data.email" class="font-normal inline" [href]="'mailto:' + data.email">
          {{ data.email }}
        </a>
        <p *ngIf="!data.email" class="font-normal inline text-neutral-1-400 dark:text-d-neutral-1-400">Chưa cập nhật</p>
      </strong>
    </app-skeleton>
  </div>

  <div class="flex gap-1">
    <i class="tdsi-domain-fill text-lg leading-none text-primary-1 dark:text-d-primary-1"></i>
    <app-skeleton [loading]="loading" size="xs" [width]="150">
      <strong>
        Website:
        <a
          *ngIf="data.website"
          tds-link
          appSkeleton
          class="font-normal inline"
          [href]="'https://' + data.website"
          target="_blank"
        >
          {{ data.website ? data.website : "" }}
        </a>

        <p class="font-normal inline text-neutral-1-400 dark:text-d-neutral-1-400" *ngIf="!data.website">
          Chưa cập nhật
        </p>
      </strong>
    </app-skeleton>
  </div>

  <div class="flex gap-1">
    <i class="tdsi-location-fill text-lg leading-none text-primary-1 dark:text-d-primary-1"></i>
    <app-skeleton block [loading]="loading" size="xs" class="w-full">
      <strong>
        Địa chỉ:
        <p class="font-normal inline" [ngClass]="{ 'text-neutral-1-400 dark:text-d-neutral-1-400': !data.address }">
          {{ data.address || "Chưa cập nhật" }}
        </p>
      </strong>
    </app-skeleton>
  </div>
</content>
