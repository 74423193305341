import { Component, Input } from '@angular/core';
import { HiddenAnimation } from '@animations/hidden';
import { CompanyDto } from 'src/app/models';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  animations: [HiddenAnimation],
})
export class CompanyComponent {
  @Input() data: CompanyDto = new CompanyDto();
  @Input() loading: boolean = true;
}
