export class CompanyDto {
  id: number = 0;
  companyName: string = '';
  taxCode: string = '';
  email: string = '';
  phoneNumber: string = '';
  fax: string = '';
  website: string = '';
  provinceName: string = '';
  districtName: string = '';
  wardName: string = '';
  detailAddress: string = '';
  extraInfo: string = '';
  logoUrl: string = '';
  logoThumbnails: string[] = [];

  constructor(data?: CompanyDto) {
    // Object.assign(this, data)
    this.id = data?.id!;
    this.companyName = data?.companyName!;
    this.taxCode = data?.taxCode!;
    this.email = data?.email!;
    this.phoneNumber = data?.phoneNumber!;
    this.fax = data?.fax!;
    this.website = data?.website!;
    this.provinceName = data?.provinceName!;
    this.districtName = data?.districtName!;
    this.wardName = data?.wardName!;
    this.detailAddress = data?.detailAddress!;
    this.extraInfo = data?.extraInfo!;
    this.logoUrl = data?.logoUrl!;
    this.logoThumbnails = data?.logoThumbnails!;
  }

  get address(): string {
    return `${this.detailAddress ? this.detailAddress : ''}${this.wardName ? ', ' + this.wardName : ''}${
      this.districtName ? ', ' + this.districtName : ''
    }${this.provinceName ? ', ' + this.provinceName : ''}`;
  }
}
