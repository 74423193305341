import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'app-root-layout',
  templateUrl: './root-layout.component.html',
  styleUrls: ['./root-layout.component.scss'],
})
export class RootLayoutComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    setTimeout(() => {
      let prevScrollpos = document.documentElement.scrollTop;

      window.onscroll = function () {
        const currentScrollPos = document.documentElement.scrollTop;
        if (prevScrollpos > 600) {
          let pageHeight = document.documentElement.offsetHeight - document.documentElement.clientHeight;
          document
            .getElementById('navBar')
            ?.setAttribute('style', `transform: translateY(${prevScrollpos > currentScrollPos ? 0 : '-100%'});`);
        }


        prevScrollpos = currentScrollPos;
      };
    }, 1000);
  }
}
