import { CoreUserInitDTO } from './user-init.dto';

export class CoreTokenDTO {
  accessToken: string = '';
  expiresIn: number = 0;
  tokenType: string = '';
  refreshToken: string = '';
  scope: string = '';
  userInfo: CoreUserInitDTO = new CoreUserInitDTO();
}
