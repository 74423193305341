import { TDSHelperObject } from 'tds-ui/shared/utility';
import { EntityDto } from '@core/dto';
import { PointStatus } from '../enums/point-status';
import { Status } from '../enums/status';
import { CompanyDto } from './company.dto';
import { ProductDto } from './product.dto';
import { VerifyDto } from './verify.dto';

export class QrcodeDto extends EntityDto {
  expireDate?: Date;
  manufacturingDate?: Date;
  status: Status = Status.inactive;
  pointStatus: PointStatus = PointStatus.none;
  productInfo: ProductDto = new ProductDto();
  productRecommend: ProductDto[] = [];
  shopInfo: CompanyDto = new CompanyDto();
  storeName: string = '';
  isVerify: boolean = false;
  verifyInfo: VerifyDto = new VerifyDto();

  constructor(data?: QrcodeDto) {
    super(data);

    Object.assign(this, data);

    this.expireDate = data?.expireDate ? new Date(data?.expireDate) : undefined;
    this.manufacturingDate = data?.manufacturingDate ? new Date(data?.manufacturingDate) : undefined;
    if (data && TDSHelperObject.hasValue(data?.shopInfo)) this.shopInfo = new CompanyDto(data?.shopInfo);
    if (data && TDSHelperObject.hasValue(data?.productInfo)) this.productInfo = new ProductDto(data?.productInfo);
  }
}
