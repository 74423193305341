<header *ngIf="dataList.length" class="flex w-full justify-center">
  <h3>Sản phẩm của doanh nghiệp</h3>
</header>

<div class="grid grid-cols-4 gap-6 max-sm:grid-cols-1 max-lg:grid-cols-2 pt-6">
  <app-product-card
    *ngFor="let data of dataList"
    class="col-span-1 "
    [data]="data"
    [company]="company"
  ></app-product-card>
</div>
