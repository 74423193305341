import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-download-banner',
  templateUrl: './download-banner.component.html',
  styleUrls: ['./download-banner.component.scss'],
})
export class DownloadBannerComponent implements AfterViewInit {
  public androidAppUrl = environment.androidAppUrl;
  public iosAppUrl = environment.iosAppUrl;

  constructor() {}
  ngAfterViewInit(): void {}
}
