<tds-card class="shadow-1-lg">
  <tds-card-content class="!p-8 !flex flex-col items-center justify-center gap-4 !min-h-[45rem]">
    <tds-empty
      class="justify-center h-full"
      notFoundImage="assets/images/empty-states/not-found-product.svg"
      [notFoundContent]="contentTemplate"
      [notFoundFooter]="footerTemplate"
    >
      <ng-template #contentTemplate>
        <div class="w-full mt-8 text-center">
          <h3 class="text-primary-1">Tra cứu không có kết quả</h3>
          <p class="font-regular text-body-1 text-neutral-1-500">
            Sản phẩm không tồn tại hoặc đã được xóa khỏi hệ thống, vui lòng kiểm tra lại hoặc liên hệ
            <a class="font font-semibold inline text-primary-1" href="tel:1900 2852"> 1900 2852 </a>
            để được hỗ trợ
          </p>
        </div>
      </ng-template>
      <ng-template #footerTemplate>
        <a tds-button type="button" href="https://wipointplatform.com/"> Quay về trang chủ </a>
      </ng-template>
    </tds-empty>
  </tds-card-content>
</tds-card>
