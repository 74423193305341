import { Injectable } from '@angular/core';
import { CoreAPIDTO } from '@core/dto';
import { CoreApiMethodType } from '@core/enum';
import { CoreCommonService, CryptoService } from '@core/services';
import { environment } from '@environments/environment';
import { from, map, switchMap } from 'rxjs';
import { QrcodeDto } from '../models';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ProductSearchService {
  constructor(private service: CoreCommonService, private cryptoService: CryptoService) {}

  getEntity(id: number) {
    const url: string = `${environment.apiService}${environment.apiPath.product.qr}`;
    const api: CoreAPIDTO = {
      method: CoreApiMethodType.get,
      url,
    };

    let time = new Date(new Date().toUTCString()).getTime().toString();
    let timeStamp = time.slice(0, 10);
    let secretKey = environment.apiKey.xApiSecret;
    let message = `${environment.apiKey.xapiKey}${id}${timeStamp}`;

    return from(this.cryptoService.generateSignature(secretKey, message)).pipe(
      switchMap((hash: string) => {
        let headers = new HttpHeaders({
          'Data-type': 'json',
          'Content-type': 'application/json;charset=utf-8',
          'Accept-Language': 'vi',
          'Access-Control-Allow-Origin': '*',
          'X-Time': timeStamp,
          'X-Sig': hash,
        });
        return this.service.connect<QrcodeDto>(api.method, url, { qr: id }, headers, false).pipe(
          map((data) => {
            return new QrcodeDto(data);
          })
        );
      })
    );
  }
}
