import { Component, Input, ViewChild } from '@angular/core';
import { HiddenAnimation } from '@animations/hidden';
import { routeFadeMotion } from '@animations/routeFade';
import { SlideInOutAnimation } from '@animations/slideInOut';
import { MediaDto } from 'src/app/models';
import { TDSScrollIntoViewComponent } from 'tds-ui/scroll-into-view';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  animations: [SlideInOutAnimation,HiddenAnimation],
})
export class GalleryComponent {
  @Input() dataList: MediaDto[] = [];
  @Input() loading: boolean = true;
  @ViewChild(TDSScrollIntoViewComponent) scrollView?: TDSScrollIntoViewComponent;

  skeletonSize = Array(5);
  selectedId: number = 0;

  onScrollTo(index: number) {
    this.selectedId = index;
    this.scrollView?.scrollIntoView(this.selectedId);
  }
}
