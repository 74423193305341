import { Component } from '@angular/core';

@Component({
  selector: 'app-phone-banner',
  templateUrl: './phone-banner.component.html',
  styleUrls: ['./phone-banner.component.scss']
})
export class PhoneBannerComponent {

}
