<div #banner class="relative flex justify-center gap-2 banner overflow-hidden px-8">
  <img
    src="assets/images/banner/location-banner.png"
    alt="location-banner"
    class="w-fit h-4/5 absolute max-md:right-0 max-md:-bottom-1 right-44 -bottom-10 z-0 object-cover"
  />

  <div class="w-full max-w-[80rem] flex z-[1]">
    <div class="flex flex-col justify-center gap-8 max-md:gap-4 relative w-full max-md:w-full">
      <!-- <img
        src="https://tmt-img.dev.tmtco.org/IDyYItkNLe-Vh0NFudy-_awVAY7a_F6KepQyUVwWvfs/rs::::0/g:no/plain/wicocheck/325819357438500/wicocheck/2024041610/localion-banner-456931401924608.png"
        alt="location-banner"
        class="w-fit h-4/5 absolute right-0 -bottom-1 z-0 object-cover"
      /> -->

      <img
        src="assets/images/banner/arrow-banner.svg"
        alt="arrow banner"
        class="absolute -left-14 rounded-full h-fit max-md:hidden"
      />
      <h2 class="relative max-md:text-heading-5 max-md:pr-8 max-md:w-4/5 flex-none">
        <p class="max-w-[750px]">Tham gia ngay để bắt đầu tích điểm đổi lấy quà hấp dẫn</p>
      </h2>

      <div class="flex relative justify-between gap-4">
        <div class="flex max-md:flex-col max-md:justify-center gap-4 max-md:gap-2 md:w-full h-full flex-none max-md:pb-4">
          <div class="flex flex-col items-center gap-2">
            <img
              src="assets/images/logos/qrcode-app.svg"
              alt="qrcode"
              class="w-40 max-md:w-[90px] rounded-lg aspect-square"
            />
            <p class="text-neutral-1-600 max-md:hidden">Quét mã để tải app</p>
          </div>

          <div class="flex flex-col justify-center gap-4 max-md:gap-2 max-md:w-[90px] md:pb-6">
            <a target="_blank" title="Phần mềm tích điểm, quà tặng - WiPoint App Store" [href]="iosAppUrl">
              <img src="assets/images/logos/app-store.svg" class="h-full w-full md:h-16" alt="logo" />
            </a>
            <a target="_blank" title="Phần mềm tích điểm, quà tặng - WiPoint Play Store" [href]="androidAppUrl">
              <img src="assets/images/logos/google-play.svg" class="h-full w-full" alt="logo" />
            </a>
          </div>
        </div>
      </div>
      <img
        src="assets/images/banner/phone-banner.png"
        alt="phone banner"
        class="md:hidden max-md:h-[35vw] max-sm:h-[50vw] absolute bottom-0 -right-8"
      />
    </div>

    <div class="h-full flex items-end justify-end w-[550px] max-lg:w-[400px] flex-none max-md:hidden">
      <img
        src="assets/images/banner/phone-banner.png"
        alt="phone banner"
        class="w-full self-end aspect-square object-contain object-bottom"
      />
    </div>
  </div>
</div>

<!-- <div #banner class="relative flex flex-col content-between gap-2 px-8 pt-16 max-w-[80rem] w-full h-full">
  <img
    src="https://tmt-img.dev.tmtco.org/IDyYItkNLe-Vh0NFudy-_awVAY7a_F6KepQyUVwWvfs/rs::::0/g:no/plain/wicocheck/325819357438500/wicocheck/2024041610/localion-banner-456931401924608.png"
    alt="location-banner"
    class="w-fit h-4/5 absolute right-0 -bottom-1 z-0 object-cover"
  />

  <img
    src="assets/images/banner/arrow-banner.svg"
    alt="arrow banner"
    class="absolute -left-14 rounded-full h-fit max-md:hidden banner-item-5"
  />
  <h2 class="relative max-md:text-heading-5 max-md:py-8 max-md:pr-8 max-md:w-4/5">
    <img
      src="assets/images/banner/line-banner.svg"
      alt="line banner"
      class="absolute -left-14 -top-10 rounded-full h-fit max-md:hidden banner-item-3"
    />
    <span
      #item1
      class="absolute left-0 top-10 rounded-full w-12 aspect-square blur-[35px] bg-primary-1 banner-item-1"
    ></span>
    <img
      #item2
      src="assets/images/banner/dot-banner.svg"
      alt="dot banner"
      class="absolute -left-4 -top-10 rounded-full w-48 h-fit banner-item-2"
    />

    <p class="banner-item-4 max-w-[750px]">
      Tham gia <span class="text-primary-1"><span class="text-base-red-500">Wi</span>Point</span> để bắt đầu hành trình
      tích điểm đổi lấy những phần quà hấp dẫn
    </p>
  </h2>

  <img
    src="assets/images/banner/phone-banner.png"
    alt="phone banner"
    class="absolute right-0 bottom-0 h-[400px]"
  />
</div> -->
