import { Component, Input } from '@angular/core';
import { CompanyDto, ProductDto } from 'src/app/models';

@Component({
  selector: 'app-product-suggestions',
  templateUrl: './product-suggestions.component.html',
  styleUrls: ['./product-suggestions.component.scss'],
})
export class ProductSuggestionsComponent {
  @Input() dataList: ProductDto[] = [];
  @Input() company!: CompanyDto;
}
