import { NgModule, isDevMode } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { TDS_I18N, vi_VN } from 'tds-ui/i18n';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
// Đa ngôn ngữ
import localeVi from '@angular/common/locales/vi';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RootLayoutComponent } from './layouts/root-layout/root-layout.component';
import { ProductSearchComponent } from './pages/product-search/product-search.component';
import { TDSCardModule } from 'tds-ui/card';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { CompanyComponent } from './components/company/company.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CoreModule } from '@core/lib.module';
import { TDSPipesModule } from 'tds-ui/core/pipes';
import { ProductSuggestionsComponent } from './components/product-suggestions/product-suggestions.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { FooterComponent } from './components/footer/footer.component';
import { DownloadBannerComponent } from './components/download-banner/download-banner.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TDSEmptyModule } from 'tds-ui/empty';
import { HeaderComponent } from './components/header/header.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { TDSScrollIntoViewModule } from 'tds-ui/scroll-into-view';
import { TDSCarouselModule } from 'tds-ui/carousel';
import { TDSMapperPipeModule } from 'tds-ui/cdk/pipes/mapper';
import { TDSDrawerModule } from 'tds-ui/drawer';
import { TDSImageModule } from 'tds-ui/image';
import { SkeletonModule } from '@UI/skeleton/skeleton.module';
import { TDSSkeletonModule } from 'tds-ui/skeleton';
import { PhoneBannerComponent } from './components/phone-banner/phone-banner.component';
// Thiết lập tiếng Việt
registerLocaleData(localeVi);
@NgModule({ declarations: [
        AppComponent,
        RootLayoutComponent,
        ProductSearchComponent,
        CompanyComponent,
        ProductSuggestionsComponent,
        ProductCardComponent,
        FooterComponent,
        DownloadBannerComponent,
        NotFoundComponent,
        HeaderComponent,
        GalleryComponent,
        PhoneBannerComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        AppRoutingModule,
        DragDropModule,
        ScrollingModule,
        CoreModule,
        TDSCardModule,
        TDSButtonModule,
        TDSAvatarModule,
        TDSPipesModule,
        TDSEmptyModule,
        TDSScrollIntoViewModule,
        TDSCarouselModule,
        TDSMapperPipeModule,
        TDSDrawerModule,
        TDSImageModule,
        SkeletonModule,
        TDSSkeletonModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        })], providers: [{ provide: TDS_I18N, useValue: vi_VN }, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {}
