<app-gallery class="block md:hidden" [dataList]="dataSet.productInfo.medias" [loading]="isLoading"></app-gallery>

<tds-card class="md:shadow-1-lg min-h-[50rem] pt-6">
  <tds-card-content class="max-md:!p-0 md:!p-8 !flex flex-col gap-4">
    <main class="grid flex-col-reverse grid-cols-11 gap-8 max-md:flex">
      <section class="flex flex-col h-full col-span-6 gap-4">
        <div class="flex flex-col gap-4 sticky top-[96px]">
          <app-skeleton block size="md" [loading]="isLoading">
            <h3 class="inline">{{ dataSet.productInfo.name }}</h3>

            <span *ngIf="dataSet.isVerify" class="inline-flex items-center text-primary-1 dark:text-d-primary-1">
              <i class="text-lg leading-none tdsi-security-line"></i>
              Chính hãng
            </span>
          </app-skeleton>

          <div class="flex flex-col gap-2">
            <app-skeleton @collapse *ngIf="dataSet.productInfo.salePrice"  [loading]="isLoading" size="sm" [width]="200">
              <h3 class="text-success-400 dark:text-d-success-400">
                {{ dataSet.productInfo.salePrice | number : "1.0-0" : "vi_VN" }} ₫
              </h3>
            </app-skeleton>

            <span class="flex gap-2">
              <p class="inline">Mã QR:</p>
              <app-skeleton [loading]="isLoading" size="xs" [width]="200">
                <strong class="text-primary-1 dark:text-d-primary-1"> {{ qrId }} </strong>
              </app-skeleton>
            </span>

            <span class="flex gap-2">
              <p class="inline">Xuất xứ:</p>
              <app-skeleton [loading]="isLoading" size="xs" [width]="200">
                <strong> {{ COUNTRIES_DICT[dataSet.productInfo.origin] || "--" }} </strong>
              </app-skeleton>
            </span>

            <span class="flex gap-2">
              <p class="inline">Điểm bán:</p>
              <app-skeleton [loading]="isLoading" size="xs" [width]="200">
                <strong> {{ dataSet.storeName || "--" }} </strong>
              </app-skeleton>
            </span>
          </div>

          <app-skeleton
            @collapse
            *ngIf="dataSet.pointStatus == PointStatus.inactive || dataSet.isVerify || isLoading"
            block
            size="xl"
            [loading]="isLoading"
            class="w-full"
          >
            <div @hiddenEnter *ngIf="dataSet.isVerify" class="block w-full">
              <div class="flex items-center justify-between px-3 py-2 rounded-lg bg-success-100 dark:bg-d-success-100">
                <div class="flex items-center w-full gap-2">
                  <img src="assets/icons/verify-icon.svg" height="24" width="24" alt="point-icon" />

                  <div class="flex flex-col justify-between">
                    <p class="text-neutral-1-400 dark:text-d-neutral-1-400">Sản phẩm này đã được xác thực bởi:</p>

                    <strong class="flex items-center gap-1 text-success-400 dark:text-d-success-400">
                      <tds-avatar size="sm" [tdsSrc]="dataSet.verifyInfo.avatarUrl"></tds-avatar>

                      <p>{{ dataSet.verifyInfo.fullName }}</p>
                      <span class="bg-neutral-1-400 dark:bg-d-neutral-1-400 w-1 aspect-square rounded-full"></span>
                      <i class="text-lg leading-none tdsi-time-line"></i>

                      <p>{{ dataSet.verifyInfo.verifyDate | date : "HH:mm - dd/MM/yyyy" }}</p>
                    </strong>
                  </div>
                </div>

                <!-- <a tds-button type="button" target="_blank" [href]="getOS() == 1 ? iosAppUrl : androidAppUrl">
                  Tích điểm
                </a> -->
              </div>
            </div>

            <div
              @hiddenEnter
              *ngIf="
                !dataSet.isVerify &&
                dataSet.pointStatus == PointStatus.inactive &&
                dataSet.productInfo.pointStatus == PointStatus.active
              "
              class="block w-full"
            >
              <div class="flex items-center justify-between px-3 py-2 rounded-lg bg-primary-3 dark:bg-d-primary-3">
                <div class="flex items-center w-full gap-2">
                  <img src="assets/icons/point-icon.svg" height="24" width="24" alt="point-icon" />

                  <p class="text-neutral-1-400 dark:text-d-neutral-1-400">Điểm tích lũy</p>

                  <strong class="text-primary-1 dark:text-d-primary-1">
                    +{{ dataSet.productInfo.point | number : "1.0-0" : "vi_VN" }}
                  </strong>
                </div>

                <a tds-button type="button" target="_blank" [href]="getOS() == 1 ? iosAppUrl : androidAppUrl">
                  Tích điểm
                </a>
              </div>
            </div>
            <div
              @hiddenEnter
              *ngIf="
                !dataSet.isVerify &&
                dataSet.pointStatus == PointStatus.inactive &&
                dataSet.productInfo.pointStatus == PointStatus.inactive
              "
              class="block w-full"
            >
              <div class="flex items-center justify-between px-3 py-2 rounded-lg bg-primary-3 dark:bg-d-primary-3">
                <div class="flex items-center w-full gap-2">
                  <img src="assets/icons/point-icon.svg" height="24" width="24" alt="point-icon" />

                  <p class="text-neutral-1-400 dark:text-d-neutral-1-400">Xác thực sản phẩm chính hãng</p>
                </div>

                <a tds-button type="button" target="_blank" [href]="getOS() == 1 ? iosAppUrl : androidAppUrl">
                  Xác thực
                </a>
              </div>
            </div>
          </app-skeleton>

          <app-company [data]="dataSet.shopInfo" [loading]="isLoading"></app-company>
        </div>
      </section>

      <aside class="col-span-5 max-md:hidden">
        <app-gallery [dataList]="dataSet.productInfo.medias" [loading]="isLoading"></app-gallery>
      </aside>
    </main>

    <content class="flex flex-col">
      <app-skeleton [loading]="isLoading" size="xs" [width]="200">
        <tds-card-title>Mô tả sản phẩm</tds-card-title>
      </app-skeleton>

      <tds-skeleton
        [tdsLoading]="isLoading"
        [tdsActive]="isLoading"
        [tdsParagraph]="{ rows: 15, width: ['100%', '100%', '100%', '100%', 100, '100%', '100%', '100%', 200] }"
      >
        <p [innerHTML]="dataSet.productInfo.descriptions | tdsSanitizer : 'html'"></p>
      </tds-skeleton>
    </content>
  </tds-card-content>
</tds-card>

<app-product-suggestions class="pt-6" [dataList]="dataSet.productRecommend" [company]="dataSet.shopInfo"></app-product-suggestions>
