<footer class="flex justify-center py-12 text-body-2 font-tds-font-family-body text-neutral-1-700 px-4">
  <section class="max-w-[80rem] w-full flex flex-col gap-8">
    <content class="grid grid-cols-3 gap-8 max-md:grid-cols-1">
      <div class="flex flex-col gap-4">
        <h6 class="pb-2">Về chúng tôi</h6>

        <a href="https://wipointplatform.com/dieu-khoan-su-dung.html">Điều khoản sử dụng</a>
        <a href="https://wipointplatform.com/chinh-sach-bao-mat.html">Chính sách bảo mật</a>
        <a href="https://wipointplatform.com/chinh-sach-thanh-toan.html">Chính sách thanh toán</a>
        <a href="https://wipointplatform.com/chinh-sach-cai-dat.html">Chính sách cài đặt</a>
        <a href="https://wipointplatform.com/chinh-sach-bao-tri.html">Chính sách bảo trì</a>
        <a href="https://wipointplatform.com/#">Chính sách thanh lý hợp đồng/hoàn tiền</a>
      </div>

      <div class="flex flex-col gap-4">
        <h6 class="pb-2">Sản phẩm</h6>

        <a href="https://wionplatform.com/phan-mem-quan-ly-san-thuong-mai-dien-tu-wion-e-commerce.html">Phần mềm quản lý sàn TMĐT WiOn Ecommerce</a>
        <a href="https://wionplatform.com/phan-mem-quan-ly-mang-xa-hoi-wion-social.html">Phần mềm quản lý mạng xã hội WiOn Social</a>
        <a href="https://wionplatform.com/phan-mem-quan-ly-nha-khoa-wion-dental.html">Phần mềm quản lý nha khoa WiOn Dental</a>
        <a href="https://wionplatform.com/phan-mem-quan-ly-ban-hang-wion-pos.html">Phần mềm quản lý bán hàng WiOn POS</a>
      </div>

      <div class="flex flex-col gap-4">
        <h6 class="pb-2">Giải pháp tăng trưởng doanh thu</h6>

        <a href="https://wipointplatform.com/#">Marketing</a>
        <a href="https://wipointplatform.com/#">CRM</a>
        <a href="https://wipointplatform.com/#">Chatbot</a>
        <a href="https://wipointplatform.com/#">Thiết kế website</a>
      </div>
    </content>

    <div class="grid grid-cols-2 max-md:grid-cols-1 gap-8">
      <div class="flex flex-col gap-4">
        <a href="/">
          <img src="assets/images/logos/logo-light.svg" class="h-[55px] w-fit" alt="logo" />
        </a>

        <strong>CÔNG TY CỔ PHẦN CÔNG NGHỆ TRƯỜNG MINH THỊNH</strong>

        <span class="block">
          <i class="tdsi-location-fill leading-none text-lg"></i>
          <p class="inline">Tầng 2,4 Lô III-26, Đường 19/5A, Nhóm CN III, Khu công nghiệp Tân Bình, Tân Phú, TP. HCM</p>
        </span>

        <span class="block">
          <i class="tdsi-call-fill leading-none text-lg"></i>
          <a class="inline" href="tel:1900 2852"> 1900 2852 </a>
        </span>
      </div>

      <div class="flex flex-col gap-4">
        <h6 class="pb-2">Tải ngay app cho điện thoại</h6>

        <div class="flex flex-col gap-6">
          <div class="flex gap-6">
            <a target="_blank" title="Phần mềm tích điểm, quà tặng - WiPoint App Store" [href]="iosAppUrl">
              <img src="assets/images/logos/app-store.svg" class="h-[52px] w-fit" alt="logo" />
            </a>
            <a target="_blank" title="Phần mềm tích điểm, quà tặng - WiPoint Play Store" [href]="androidAppUrl">
              <img src="assets/images/logos/google-play.svg" class="h-[52px] w-fit" alt="logo" />
            </a>
          </div>

          <div class="block">
            <img
              src="https://static.tds-ui.public.dev.tmtco.org/webcontent/wipix/image/meta-business-partner.svg"
              alt="meta partner"
              class="h-[100px] border border-neutral-3-50 rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</footer>
