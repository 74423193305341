export class UploadResponseDto {
    key?: string;
    url?: string;
    urlImageProxy: string = '';
    thumbnails: string[] = [];
    name: string = '';
    lastModified?: string;
    eTag?: string;
    size: number = 0;
    isDir?: boolean;
    hasChild?: boolean;
    lastModifiedDateTime?: string;
}
