<!-- <div class="w-screen h-full absolute">
  <span
    class="-z-[1] absolute-centered-x -top-1/2 aspect-[3/1] h-[110vh] max-md:h-[75vh] rounded-[100%] bg-primary-1 dark:bg-d-primary-1"
  ></span>
</div> -->

<app-header id="navBar" class="w-full sticky top-0 z-20 transition-transform"></app-header>
<app-download-banner class="w-screen"></app-download-banner>

<main class="max-w-[80rem] w-full flex flex-col gap-2 p-4 z-10">
  <router-outlet></router-outlet>
</main>

<app-footer></app-footer>
